import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  template: `
  <!-- footer -->
  <footer class="footer">
    <div class="container content has-text-centered">
      <p>Made by Gerami Solutions 🎬</p>
    
    </div>

  </footer>
  `,
  styles: [
  ]
})
export class FooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
