import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  template: `
    <!--Header -->
   <app-header></app-header>
  
    <app-home></app-home>
    <!-- routes get injected here -->
    <router-outlet></router-outlet>

   <app-footer></app-footer>
  `,
  styles: []
})
export class AppComponent {
  title = 'my-portfolio-site';
}
